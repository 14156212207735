import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { Query } from 'react-apollo';
import { defineMessages, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { token } from '@atlaskit/tokens';

import { Attribution, ErrorBoundary, GenericErrorBoundary } from '@confluence/error-boundary';
import {
	SPACE_OVERVIEW,
	VIEW_BLOG,
	VIEW_PAGE,
	SPACE_ARCHIVED_PAGES,
	WHITEBOARD_BOARD,
	DATABASE_VIEW,
	EMBED_VIEW,
} from '@confluence/named-routes';
import { generateSlug, RoutesContext } from '@confluence/route-manager';
import { useIsWhiteboardFeatureEnabled } from '@confluence/whiteboard-utils';
import { usePageSpaceKey } from '@confluence/page-context';
import { isCompanyHubSpaceKey } from '@confluence/route-manager/entry-points/companyHubUtils';
import { getQueryPropsAndSetFetchPolicyForNetworkOnlyQuery } from '@confluence/query-preloader-tools';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { useSSRPlaceholderReplaceIdProp, LoadableAfterPaint } from '@confluence/loadable';

import type {
	BreadcrumbsQuery as BreadcrumbsQueryT,
	BreadcrumbsQuery_content_nodes,
	BreadcrumbsQuery_content_nodes_ancestors,
	BreadcrumbsQueryVariables,
} from './__types__/BreadcrumbsQuery';
import type { BreadcrumbsComponentProps } from './BreadcrumbsComponent';
import { BreadcrumbsPlaceholder } from './BreadcrumbsPlaceholder';
import { BreadcrumbsQuery } from './BreadcrumbsQuery.graphql';
import { getSkipContentAndTypes } from './util';
import { BreadcrumbsPicker } from './BreadcrumbsPicker';

const CompanyHubBreadcrumbs = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CompanyHubBreadcrumbs" */ './company-hub/CompanyHubBreadcrumbs'
			)
		).CompanyHubBreadcrumbs,
});

export const CONTENT_STATUS_ARCHIVED = 'archived';

const i18n = defineMessages({
	archivedPagesBreadcrumbsTitle: {
		id: 'breadcrumbs.archived-pages-list-link',
		defaultMessage: 'Archived pages',
		description:
			'The text for the link to the list of archived pages, appearing in the breadcrumbs trail on an archived page',
	},
	archivedContentBreadcrumbsTitle: {
		id: 'breadcrumbs.archived-content-list-link',
		defaultMessage: 'Archived',
		description:
			'The text for the link to the list of archived content, appearing in the breadcrumbs trail on an archived page',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FixedSpaceHeaderContainer = styled.div<{
	isThemed?: boolean;
	isFullWidth?: boolean;
	isTransparent?: boolean;
	justifyContent?: 'space-between' | 'flex-end';
}>({
	height: '59px',
	// needs to be above 10 so it covers floating sticky table rows
	// also headers are not fixed for themed spaces
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	zIndex: 12,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: (p) =>
		p.isFullWidth
			? 'none'
			: 'calc( 100vw - var(--leftPanelWidth, 0px) - var(--leftSidebarWidth, 0px) - var(--rightPanelWidth, 0px) - var(--rightSidebarWidth, 0px) )',
	borderBottom: 'none',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	position: (p) => (p.isThemed ? 'sticky' : 'fixed'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: (p) => (p.isThemed ? '0px' : '0 28px'),
	display: 'flex',
	alignItems: 'center',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (p) => (p.isTransparent ? 'transparent' : token('elevation.surface', '#fff')),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	left: (p) => (p.isFullWidth ? '0' : 'var(--leftSidebarWidth)'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	justifyContent: (p) => p.justifyContent || 'space-between', // justifyContent is used for company hub specifically
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BreadcrumbWrapper = styled.nav<{
	compact?: boolean;
}>({
	marginRight: token('space.200', '16px'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	fontSize: (props) => (props.compact ? '12px' : '14px'),
	display: 'flex',
	alignItems: 'center',
	flexGrow: 0,
	minWidth: 0, // needed for responsive breadcrumbs
});

function filterAncestors(
	{ ancestors, status }: BreadcrumbsQuery_content_nodes,
	spaceHomepageId: string | null | undefined,
): BreadcrumbsQuery_content_nodes_ancestors[] {
	if (!ancestors) {
		return [];
	}

	// If current page is archived, filter out non-archived ancestors
	if (status === CONTENT_STATUS_ARCHIVED) {
		ancestors = ancestors.filter(
			(ancestor) => ancestor && ancestor.status === CONTENT_STATUS_ARCHIVED,
		);
	} else {
		// technically array elements can be null
		ancestors = ancestors.filter((node) => node);
	}

	if (!ancestors.length) {
		return ancestors as BreadcrumbsQuery_content_nodes_ancestors[];
	}
	// See https://jira.atlassian.com/browse/CONFDEV-57663
	if (
		ancestors[0]!.id !== spaceHomepageId &&
		ancestors[ancestors.length - 1]!.id === spaceHomepageId
	) {
		return ancestors.reverse().slice(1) as BreadcrumbsQuery_content_nodes_ancestors[];
	}
	if (ancestors[0]!.id === spaceHomepageId) {
		return ancestors.slice(1) as BreadcrumbsQuery_content_nodes_ancestors[];
	}
	return ancestors as BreadcrumbsQuery_content_nodes_ancestors[];
}

export type BreadcrumbsProps = {
	compact?: boolean;
	contentId?: string;
	contentTitle?: string;
	spaceKey?: string;
	includeSelf?: boolean;
};

type BuildContentPathType = {
	contentId?: string | null;
	contentSlug?: ReturnType<typeof generateSlug>;
	contentType: string | null;
	query?: any;
	spaceAlias?: string | null;
};

Breadcrumbs.propTypes = {
	contentId: PropTypes.string,
	/**
	 * Show an extra breadcrumb item when it is provided.
	 * This is used in sticky content header where we want to display the title of the current page.
	 */
	contentTitle: PropTypes.string,
	spaceKey: PropTypes.string.isRequired,
	/**
	 * Include the current content when true
	 */
	includeSelf: PropTypes.bool,
};

export function Breadcrumbs({
	compact,
	contentId,
	contentTitle,
	spaceKey,
	includeSelf,
}: BreadcrumbsProps) {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const { match } = useContext(RoutesContext);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isSpaceAliasFFEnabled = useBooleanFeatureFlag('confluence.frontend.space.alias');
	const { isWhiteboardFeatureEnabled } = useIsWhiteboardFeatureEnabled();
	const intl = useIntl();

	if (!spaceKey) {
		return null;
	}
	if (isCompanyHubSpaceKey(spaceKey)) {
		return <CompanyHubBreadcrumbs />;
	}

	const fireBreadcrumbError = (error: Error) => {
		let err: string | undefined;
		let nativeError: string | undefined;
		try {
			err = JSON.stringify(error, Object.getOwnPropertyNames(error)); // https://stackoverflow.com/a/26199752
			nativeError = JSON.stringify(error['nativeError']);
		} catch {
			// if no error, means it failed to stringify so just set as empty string
			if (!err) err = '';
			if (!nativeError) nativeError = '';
		} finally {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					source: 'breadcrumbs',
					action: 'errored',
					actionSubject: 'viewPageBreadcrumbs',
					attributes: {
						message: error.message,
						error: err,
						nativeError,
					},
				},
			}).fire();
		}
	};

	const buildContentPath = ({
		contentId,
		contentSlug,
		contentType,
		query,
		spaceAlias,
	}: BuildContentPathType) => {
		let route: typeof VIEW_PAGE;
		switch (contentType) {
			case 'overview':
				route = SPACE_OVERVIEW;
				break;
			case 'blogpost':
				route = VIEW_BLOG;
				break;
			case 'page':
				route = VIEW_PAGE;
				break;
			case 'whiteboard':
				route = WHITEBOARD_BOARD;
				break;
			case 'database':
				route = DATABASE_VIEW;
				break;
			case 'embed':
				route = EMBED_VIEW;
				break;
			case 'folder':
				return '#';
			default:
				throw new Error(`Received an unexpected content type: '${contentType}'`);
		}
		return route.toUrl({ contentId, contentSlug, spaceKey: spaceAlias || spaceKey }, { query });
	};

	return (
		<ErrorBoundary attribution={Attribution.DISCO}>
			<GenericErrorBoundary
				attribution={Attribution.DISCO}
				onError={fireBreadcrumbError}
				renderOnError={() => null}
			>
				<Query<BreadcrumbsQueryT, BreadcrumbsQueryVariables>
					{...getQueryPropsAndSetFetchPolicyForNetworkOnlyQuery({
						query: BreadcrumbsQuery,
						context: { single: true },
						variables: {
							spaceKey,
							contentId,
							skipContent: !contentId,
							includeAlias: isSpaceAliasFFEnabled,
						},
						// No fetch policy here because we are relying on network-only preloader to get the latest data
					})}
				>
					{({ data, error }) => {
						// Note don't check for loading as when refreshing data we will get data={...} and loading=true
						// In this case if we display the placeholder it will flash before the data is displayed
						// We should continue render with existing data and then update when the new data is available

						if (!data || error) {
							return <BreadcrumbsPlaceholder />;
						}
						const space = data?.spaces?.nodes?.[0];
						const content = data?.content?.nodes?.[0];

						// we need both space data and content (if requested)
						if (!space) {
							// @TODO fixme: should we really silently return null here?
							// If it's possible for us to get graphql data but not these
							// fields, something seems wrong
							return null;
						}

						const spaceHomepageId = space?.homepage?.id;
						const spaceOverviewPath = buildContentPath({
							contentType: 'overview',
							query: spaceHomepageId
								? { [SPACE_OVERVIEW.HOMEPAGE_ID_QUERY_KEY]: spaceHomepageId }
								: undefined,
							spaceAlias: space.alias,
						});

						const ancestors = content
							? filterAncestors(content, spaceHomepageId).map(
									({ id, title, type }): BreadcrumbsComponentProps['ancestors'][0] => ({
										href: buildContentPath({
											contentId: id,
											contentSlug: generateSlug(title || undefined),
											contentType: type,
											spaceAlias: space.alias,
										}),
										id: id || '',
										title: title || '',
										contentType: type || undefined,
									}),
								)
							: [];

						if (
							content?.status === CONTENT_STATUS_ARCHIVED ||
							getSkipContentAndTypes(match?.name).isArchivedPagesList
						) {
							const archivePagesListLink = {
								href: SPACE_ARCHIVED_PAGES.toUrl({
									spaceKey: (isSpaceAliasFFEnabled && space.alias) || spaceKey,
								}),
								title: intl.formatMessage(
									isWhiteboardFeatureEnabled('whiteboardsEnabled')
										? i18n.archivedContentBreadcrumbsTitle
										: i18n.archivedPagesBreadcrumbsTitle,
								),
								id: 'archived_pages_list',
							};
							ancestors.unshift(archivePagesListLink);
						}

						if (includeSelf && content) {
							const { title, id, type } = content;
							if (title && id && type) {
								const self = {
									href: buildContentPath({
										contentId: id,
										contentSlug: generateSlug(title),
										contentType: type,
										spaceAlias: space.alias,
									}),
									id,
									title,
								};

								// add yourself as an ancestor in breadcrumbs
								ancestors.push(self);
							}
						}

						return (
							<BreadcrumbWrapper
								compact={compact}
								aria-label="Breadcrumbs"
								data-vc="breadcrumbs-loader"
								{...ssrPlaceholderIdProp}
							>
								<BreadcrumbsPicker
									ancestors={ancestors}
									contentTitle={contentTitle || ''}
									spaceName={space.name || space.alias || spaceKey}
									spaceOverviewPath={spaceOverviewPath}
									compact={compact}
								/>
							</BreadcrumbWrapper>
						);
					}}
				</Query>
			</GenericErrorBoundary>
		</ErrorBoundary>
	);
}

export const FixedBreadcrumbsNavForPageLayout = () => {
	const [spaceKey] = usePageSpaceKey();
	return (
		<FixedSpaceHeaderContainer>
			<Breadcrumbs spaceKey={spaceKey} />
		</FixedSpaceHeaderContainer>
	);
};
